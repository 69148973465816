import React from 'react'
import style from './style.module.scss'
import Dinamiza from 'components/colorDinamiza'
import AniLink from "gatsby-plugin-transition-link/AniLink";

class FooterCase extends React.Component{
  render() {

    const { prevProject, nextProject, nextProjectColor } = this.props
    const basePathProjects = '/projects/'

    return (
      <div className={style.section}>

        <div className={style.section__linkProjects}>
          <AniLink className={style.section__linkProjects__prev} to={basePathProjects+prevProject} cover direction="right" bg={'#2E2E2F'}>
            <div>
              <span>Precedente</span>
              <span className={style.section__linkProjects__link}>{prevProject}</span>
            </div>
          </AniLink>
          <AniLink className={style.section__linkProjects__next} to={basePathProjects+nextProject} cover direction="left" bg={'#2E2E2F'}>
            <div>
              <span>Successivo</span>
              <span className={style.section__linkProjects__link}>{nextProject}</span>
            </div>
          </AniLink>
        </div>

        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <h4>Thank <Dinamiza>you</Dinamiza><br/>for watching</h4>
            </div>
          </div>
        </div>

        <a
          ref={this.logoRef}
          href={basePathProjects+nextProject}
          className={style.section__logoContainer}
          style={{ backgroundColor: nextProjectColor }}
        >
          <img src={'/img/projects/'+nextProject+'/logo_'+nextProject+'.svg'} />
        </a>

      </div>
    )
  }
}

export default FooterCase
