import {Helmet} from "react-helmet/es/Helmet";
import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import HeaderCase from 'blocks/case/headerCase'
import SectionHalfIntroduction from 'blocks/case/sectionHalfIntroduction'
import SectionTextImageContainer from 'blocks/case/sectionTextImageContainer'
import ParagraphLine from 'components/paragraphLine'
import style from 'scss/pages/projects/vega.module.scss'
import SectionDark from 'components/sectionDark'
import SectionTextImageFullWidth from 'blocks/case/sectionTextImageFullWidth'
import SectionSlider from 'blocks/case/sectionSlider'
import FooterCase from 'blocks/case/footerCase'
import VideoIllustration from "blocks/case/videoIllustration";
import AnimationSportelloAiti from "images/projects/aiti/sportello-aiti.json"

class Aiti extends React.Component {

  render() {

    const { location, data } = this.props

    const imagePath = '/img/projects/aiti/'

    const headerInfo = {
      data: "22.08.2019",
      color: "#b10a33",
      logoUrl: imagePath+'logo_aiti.svg',
      image: imagePath+'copertina.png',
      title: "Il sito istituzionale dell'Associazione Italiana interpreti e traduttori",
      tags: ["UI-UX DESIGN","BRAND IDENTITY","PROJECT MANAGEMENT","SOFTWARE ARCHITECTURE","WEB DEVELOPMENT"]
    }

    const slideImages = ['/img/screenshot/aiti1.jpg', '/img/screenshot/aiti2.jpg', '/img/screenshot/aiti3.jpg']

    return(
      <div>
        <Layout location={location}>

          <Meta site={siteMetadata} title="AITI" description={'Dinamiza è il partner tecnologico per lo sviluppo front-end e backend di piattaforme web, siti web ecommerce integrati a CRM, sistemi di automation.'} />

          <HeaderCase data={headerInfo} />

          <SectionHalfIntroduction>
            <h2>Promozione dell'associazione e digitalizzazione dei servizi per gli associati</h2>
            <p>AITI, Associazione Italiana Traduttori e interpreti, è una Associazione attiva e numerosa che si avvale del sito web per gestire tutte le attività di comunicazione sia esterne che interne. L’esigenza principale è quella di gestire gli accreditamenti degli associati, la valutazione professionale e le richieste di adesione.</p>
              <ul>
                <li>Portabilità della struttura dati dal del sito precedente migliore usabilità</li>
                <li>Sito multidominio e multilingua</li>
                <li>Analisi e consulenza per la definizione della nuova struttura del database degli iscritti</li>
                <li>Sito responsive </li>
              </ul>
          </SectionHalfIntroduction>

          <SectionTextImageFullWidth textPosition={'left'} padding={false} imageFade={false}>
            <img src={imagePath+'identity_aiti.png'} style={{ marginRight: 'auto' }} className={style.page__firstImage} />
            <ParagraphLine>
              <h2 data-title>Nuova identità e nuova UX</h2>
              <p>Per gestire al meglio l’introduzione di nuove funzionalità e contenuti del sito è stata effettuata l’analisi delle personas e una prototipazione dei wireframe piuttosto minuziosa che tenesse conto di tutte le sfacettature nella gestione burocratica dei processi di accreditamento e adesione.</p>
            </ParagraphLine>
          </SectionTextImageFullWidth>

          <SectionTextImageContainer textPosition={'right'}>
            <ParagraphLine>
              <h2 data-title>Erogazione dei servizi associato di sportello  tramite web</h2>
              <p>AITI adotta flussi di gestione specifici e diverse commissioni per i servizi erogati agli associati. Trasformarli in funzionalità per consentire una totale gestione digitale del business ha richiesto uno studio approfondito dell’architettura molto complessa e profilata a livello di ruoli e privilegi.</p>
            </ParagraphLine>
            <div>
              <VideoIllustration src={AnimationSportelloAiti} className={style.page__firstImage} />
            </div>
          </SectionTextImageContainer>

          <SectionDark imageSrc={imagePath+'bg_aiti.jpg'} imagePosition={'top'}>

            <SectionTextImageFullWidth textPosition={'right'} padding={false} imageFade={false}>
              <img src={imagePath+'multidominio_aiti.png'} style={{ margin: 'auto' }} className={style.page__firstImage} />
              <ParagraphLine>
                <h2 data-title className={`has-color-primary-negative`}>Multidominio<br />e multilingua</h2>
                <p className={`has-color-primary-negative`}>La personalizzazione avanzata della piattaforma cms ha permesso la gestione multidominio del sito e multilingua permette un sito dedicato per ogni sezione regionale dell’associazione, basato sulle necessità di ogni singola unità regionale.</p>
              </ParagraphLine>
            </SectionTextImageFullWidth>

            <SectionSlider slidesData={slideImages}>
              <h2 className={`has-color-primary-negative`}>Risultati</h2>
              <p className={`has-color-primary-negative`}>Tra i risultati raggiunti, la semplificazione della gestione dei dati grazie a una migliore usabilità del sito.
                La struttura del database degli iscritti è stata migliorata in base alle necessità condivise dal cliente, dopo un lavoro di analisi e consulenza.</p>
            </SectionSlider>

          </SectionDark>

          <FooterCase prevProject={'enternow'} nextProject={'petra'} nextProjectColor={'#ffca30'} />

        </Layout>
      </div>
    )

  }
}

export default Aiti
